.pageWrapper {
  display: flex;
  align-content: center;
  justify-content: center;
  text-align: center;
  height: calc(100vh - 485px);
  min-height: 550px;

  h1 {
    font-size: 18px;
    font-weight: bold;
    color: #202325;
    margin-bottom: 16px;
  }
}

.imageWrapper {
  margin-bottom: 16px;
  margin-top: 100px;

  > span {
    position: relative !important;
    width: 200px !important;
    height: 200px !important;
  }
}

.footerBtn {
  color: white;
  display: inline-block;
  padding-left: 20px;
  padding-right: 20px;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  font-weight: 600;
  transition: transform 0.3s, box-shadow 0.3s, -webkit-box-shadow 0.3s,
    -webkit-transform 0.3s;
  border-radius: 20px;
  background-image: linear-gradient(131deg, #3ac4fb 15%, #178bec 86%);
  cursor: pointer;
  border: none;

  &:hover {
    transform: translateY(-2px);
  }
}
